import { Component, output, signal } from '@angular/core';
import { ProductSelectComponent } from '../../product-select/product-select.component';
import { ISapiProduct } from '../../../../model/subscription-api/sapi-product';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IPaymentIdRequest } from '../../../../model/request/i-payment-id.request';

@Component({
  selector: 'payment-id-request',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ProductSelectComponent,
    TranslateModule
  ],
  templateUrl: './payment-id-request.component.html',
  styleUrl: './payment-id-request.component.css'
})
export class PaymentIdRequestComponent {

  submitRequest = output<IPaymentIdRequest>();

  paymentId = signal<string>('');
  productId = signal<string>('');

  onProductSelected(product: ISapiProduct | undefined) {
    if (product) {
      this.productId.set(product.id);
    }
  }
  onSubmit(): void {
    this.submitRequest.emit(
      {
        productId: this.productId(),
        paymentId: this.paymentId()
      }
    );
  }

}
