<form #createSingleform="ngForm">
    <product-select name="product-id" (productSelected)="onProductSelected($event)">
    </product-select>

    <mat-form-field>
        <mat-label>{{'crete_single.payment_id_label' | translate}}</mat-label>

        <input name="payment-id" matInput [(ngModel)]="paymentId" #payment_id="ngModel">

        <mat-hint align="start">{{'crete_single.payment_id_hint' | translate}}</mat-hint>

        @if (paymentId().length > 0) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="paymentId.set('')">
            <mat-icon>close</mat-icon>
        </button>
        }
        <button matSuffix mat-icon-button aria-label="Create" (click)="createPaymentId()" required>
            <mat-icon>create</mat-icon> <!-- Change 'create' to another icon if preferred -->
        </button>
    </mat-form-field>

    <email-input [(email)]="email" [(isValid)]="emailValid"></email-input>


    <mat-form-field>
        <mat-label>{{'crete_single.language_label' | translate}}</mat-label>

        <mat-select name="language" [(value)]="selectedLanguage">
            @for (languiage of languageList(); track languiage) {

            <mat-option value="{{languiage}}">{{languiage}}</mat-option>
            }
        </mat-select>
    </mat-form-field>

    @if (selectedLanguage().length == 0) {
    <mat-error>{{'crete_single.language_required' | translate}}</mat-error>
    }

    <button mat-raised-button class="create-button" [disabled]="!createSingleform.form.valid || !emailValid()"
        (click)="onSubmit()">
        {{'crete_single.create_button_label' | translate}}
    </button>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>{{'address_panel.title' | translate}}</mat-panel-title>
            <mat-panel-description>
                {{'address_panel.description' | translate}}
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-form-field>
            <mat-label>{{'address_panel.first_name' | translate}}</mat-label>

            <input name="first-name" matInput [(ngModel)]="firstName">

            @if (firstName().length > 0) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="firstName.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'address_panel.last_name' | translate}}</mat-label>

            <input name="last-name" matInput [(ngModel)]="lastName">
            @if (lastName().length > 0) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="lastName.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'address_panel.street' | translate}}</mat-label>

            <input name="street" matInput [(ngModel)]="street">

            @if (street().length > 0) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="street.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'address_panel.city' | translate}}</mat-label>

            <input name="city" matInput [(ngModel)]="city">

            @if (city().length > 0) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="city.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>

        <mat-form-field>

            <mat-label>{{'address_panel.zip' | translate}}</mat-label>
            <input name="zip" matInput [(ngModel)]="zip">

            @if (zip().length > 0) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="zip.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'address_panel.country' | translate}}</mat-label>

            <input name="country" matInput [(ngModel)]="country">

            @if (country().length > 0) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="country.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'address_panel.company' | translate}}</mat-label>

            <input name="company" matInput [(ngModel)]="company">

            @if (company().length > 0) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="company.set('')">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>

    </mat-expansion-panel>
</form>