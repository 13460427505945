import { Component, inject, signal } from '@angular/core';
import { ReqRespTab, ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { IUserIdRequest } from '../../../model/request/i-user-id.request';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { SubscriptionOutputFormComponent } from '../../common/subscription-output-form/subscription-output-form.component';
import { ErrorResponseComponent } from '../../common/error-response/error-response.component';
import { UserIdRequestComponent } from '../../common/request/user-id/user-id-request.component';

@Component({
  selector: 'app-read-subscription-by-user-id',
  standalone: true,
  imports: [
    ErrorResponseComponent,
    UserIdRequestComponent,
    ReqRespTabGroupComponent,
    SubscriptionOutputFormComponent
  ],
  templateUrl: './read-subscription-by-user-id.component.html',
  styleUrl: './read-subscription-by-user-id.component.css'
})
export class ReadSubscriptionByUserIdComponent {

  sapi: SubscriptionApiService = inject(SubscriptionApiService);

  activeTabIndex = signal<ReqRespTab>(ReqRespTab.REQUEST);
  errorResponse = signal<HttpErrorResponse | null>(null);
  errorVisible = signal<boolean>(false);
  responseDisabled = signal<boolean>(true);
  responseVisible = signal<boolean>(true);
  subscription = signal<SapiSubscription | null>(null);


  onSubmit(params: IUserIdRequest): void {
    this.sapi.readByUserId(
      params.productId,
      params.userId
    ).subscribe(
      {
        next: subscription => {
          this.activeTabIndex.set(ReqRespTab.RESPONSE);
          this.errorVisible.set(false);
          this.responseDisabled.set(false);
          this.responseVisible.set(true);
          this.subscription.set(new SapiSubscription(subscription));
          console.log(subscription);
        },
        error: error => {
          this.activeTabIndex.set(ReqRespTab.ERROR);
          this.errorVisible.set(true);
          this.responseVisible.set(false);
          this.errorResponse.set(error);
          console.log(error);
        }
      }
    );

  }

}
