<mat-form-field>
    <mat-label>{{'email_input.label' | translate}}</mat-label>

    <input name="email" 
        autocomplete="email"
        type="email" 
        matInput 
        placeholder="{{'email_input.placeholder' | translate}}"
        required
        #e_mail="ngModel"
        [(ngModel)]="email"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="validateEmail()">
</mat-form-field>
@if(e_mail.dirty || e_mail.touched) {

    @if (e_mail.invalid && e_mail.errors?.['required']) {
        <mat-error>{{'email_input.required' | translate}}</mat-error>
    }

    @if (!e_mail.invalid && !isValid()) {
        <mat-error>{{'email_input.error' | translate}}</mat-error>
    }
}

