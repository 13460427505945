import { inject, Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ConfirmDialogComponent, IConfirmDialogParams } from "./confirm-dialog.component";

@Injectable(
    { providedIn: 'root' }
)
export class ConfirmDialogService {

    private dialog: MatDialog = inject(MatDialog);

    public show(titleId: string, messageId: string): Observable<any> {

        const params: IConfirmDialogParams = {
            cancelBtnLabelId: 'confirm_dialog.no',
            confirmBtnLabelId: 'confirm_dialog.yes',
            messageId: messageId,
            titleId: titleId
        }

        const dialogRef: MatDialogRef<ConfirmDialogComponent, any> =
            this.dialog.open(ConfirmDialogComponent, { data: params });

        return dialogRef.afterClosed();
    }

}