import { Component, computed, input } from '@angular/core';
import { SapiDevice } from '../../../model/subscription-api/sapi-device';
import { OutputFormRowComponent } from '../output-form-row/output-form-row.component';

@Component({
  selector: 'device-output-form',
  standalone: true,
  imports: [OutputFormRowComponent],
  templateUrl: './device-output-form.component.html',
  styleUrl: './device-output-form.component.css'
})
export class DeviceOutputFormComponent {

  device = input<SapiDevice | null>(null);

   type=computed(() => this.device()?.type || '');
   deviceId=computed(() => this.device()?.deviceId || '');
   model=computed(() => this.device()?.model || '');
   operationSystem=computed(() => this.device()?.operationSystem || '');
}
