import { Component } from '@angular/core';
import { ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { CreateSubscriptionMultipleRequestComponent } from './request/create-subscription-multiple-request.component';
import { CreateSubscriptionMultipleResponseComponent } from './response/create-subscription-multiple-response.component';

@Component({
  selector: 'create-subscription-multiple',
  standalone: true,
  imports: [
    CreateSubscriptionMultipleRequestComponent,
    CreateSubscriptionMultipleResponseComponent,
    ReqRespTabGroupComponent
  ],
  templateUrl: './create-subscription-multiple.component.html',
  styleUrl: './create-subscription-multiple.component.css'
})
export class CreateSubscriptionMultipleComponent {

}
