<form #loginForm="ngForm">

    <mat-form-field>
        <mat-label>{{'login.user_id_label' | translate}}</mat-label>

        <input name="user-id" matInput [(ngModel)]="userId" required #user_id="ngModel">

        @if (userId().length > 0) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="userId.set('')">
            <mat-icon>close</mat-icon>
        </button>
        }

        @if((user_id.dirty || user_id.touched) &&
        (user_id.invalid && user_id.errors?.['required'])
        ) {
        <mat-error>{{'login.user_id_required' | translate}}</mat-error>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'login.password_label' | translate}}</mat-label>

        <input name="password" [type]="hidePassword() ? 'password' : 'text'" matInput [(ngModel)]="password" required
            #passwordInput="ngModel">

        <button matSuffix mat-icon-button aria-label="Toggle password visibility" (click)="togglePasswordVisibility()">
            <mat-icon>{{ hidePassword() ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>

        @if (password().length > 0) {

        <button matSuffix mat-icon-button aria-label="Clear" (click)="password.set('')">
            <mat-icon>close</mat-icon>
        </button>
        }
        @if((passwordInput.dirty || passwordInput.touched) &&
        (passwordInput.invalid && passwordInput.errors?.['required'])
        ) {
        <mat-error>{{'login.password_required' | translate}}</mat-error>
        }
    </mat-form-field>

    <button mat-raised-button class="create-button" [disabled]="!loginForm.form.valid" (click)="onSubmit()">
        {{'login.login_button_label' | translate}}
    </button>

</form>