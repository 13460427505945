import { Injectable } from '@angular/core';
import { SessionStorageFeature, SessionStorageKey } from './session-storage-key';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  /**
   * Class constructor.
   */
  constructor() {
  }

  clear(): void {
    sessionStorage.clear();
  }

  delete(feature: SessionStorageFeature): void {
    sessionStorage.removeItem(SessionStorageKey.get(feature));
  }

  has(feature: SessionStorageFeature): boolean {

    return sessionStorage[SessionStorageKey.get(feature)] != null;
  }

  get(feature: SessionStorageFeature, defaultValue: any = null): any {

    const strValue = sessionStorage[SessionStorageKey.get(feature)];
    return strValue != null ? JSON.parse(strValue) : defaultValue;
  }

  set(feature: SessionStorageFeature, value: any): void {
    const key: string = SessionStorageKey.get(feature);
    sessionStorage[key] = JSON.stringify(value);
  }



}
