<req-resp-tab-group
    [(activeTabIndex)]="activeTabIndex"
    [errorVisible]="errorVisible()"
    [responseDisabled]="responseDisabled()" 
    [responseVisible]="responseVisible()" 
>
    <email-request class="req-tab" (submitRequest)="onSubmit($event)">
    </email-request>

    <subscription-list class="resp-tab" [subscriptions]="subscriptions()">
    </subscription-list>

    <error-response class="error-tab" [error]="errorResponse()">
    </error-response>
</req-resp-tab-group>