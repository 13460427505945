<mat-form-field>
    <mat-label>{{'product_select.product_label' | translate}}</mat-label>
    <mat-select [(value)]="selectedProductId">
        @for (product of productList(); track product.id) {

        <mat-option value="{{product.id}}">{{product.name}}</mat-option>
        }
    </mat-select>
</mat-form-field>
@if (selectedProductId().length == 0) {
<mat-error>{{'product_select.product_required' | translate}}</mat-error>
}