import { HttpErrorResponse } from '@angular/common/http';
import { Component, input } from '@angular/core';

@Component({
  selector: 'error-response',
  standalone: true,
  imports: [],
  templateUrl: './error-response.component.html',
  styleUrl: './error-response.component.css'
})
export class ErrorResponseComponent {

  error = input<HttpErrorResponse | null>(null);

}
