<div class="device-container">

    <mat-form-field>
        <mat-label>{{'device_info.type' | translate}}</mat-label>

        <input name="type" matInput [(ngModel)]="device().type" readonly>

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'device_info.device_id' | translate}}</mat-label>

        <input name="device_id" matInput [(ngModel)]="device().deviceId">

        @if (device().deviceId.length > 0) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="device().deviceId=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'device_info.model' | translate}}</mat-label>

        <input name="model" matInput [(ngModel)]="device().model">

        @if (device().model.length > 0) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="device().model=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'device_info.operation_system' | translate}}</mat-label>

        <input name="operation_system" matInput [(ngModel)]="device().operationSystem">

        @if (device().operationSystem.length > 0) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="device().operationSystem=''">
            <mat-icon>close</mat-icon>
        </button>
        }

    </mat-form-field>

</div>
