<div class="container">

    <div class="message" [innerHtml]="message">
    </div>

    <div class="button_bar">

        <button mat-raised-button class="ok-button" (click)="onConfirm()">
            {{'alert_dialog.ok' | translate}}
        </button>

    </div>
</div>