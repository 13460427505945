<nav mat-tab-nav-bar mat-stretch-tabs="false" mat-align-tabs="start" [tabPanel]="tabPanel">

    @for (link of links; track link) {

    <a mat-tab-link (click)="activeLink = link" [active]="activeLink.id == link.id" [routerLink]="link.path">
        {{link.text | translate}}
    </a>
    }
</nav>
<mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>