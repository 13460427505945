import { Component, effect, output, signal } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ISapiProduct } from '../../../../model/subscription-api/sapi-product';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProductSelectComponent } from '../../../common/product-select/product-select.component';
import { EmailInputComponent } from '../../../common/email-input/email-input.component';
import { ICreateSubscriptionSingleRequest } from '../../../../model/request/i-create-subscription-single.request';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'create-subscription-single-request',
  standalone: true,
  imports: [
    EmailInputComponent,
    FormsModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ProductSelectComponent,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './create-subscription-single-request.component.html',
  styleUrl: './create-subscription-single-request.component.css'
})
export class CreateSubscriptionSingleRequestComponent {

  submitRequest = output<ICreateSubscriptionSingleRequest>();

  email = signal<string>('');
  emailValid = signal<boolean>(false);
  firstName = signal<string>('');
  lastName = signal<string>('');
  street = signal<string>('');
  zip = signal<string>('');
  city = signal<string>('');
  country = signal<string>('');
  company = signal<string>('');
  paymentId = signal<string>(new Date().getTime().toString());
  productId = signal<string>('');

  languageList = signal<string[]>([]);

  selectDefaultLanguage = effect(
    () => {
      this.selectedLanguage.set(
        this.languageList().length > 0 ? this.languageList()[0] : ''
      );
    },
    { allowSignalWrites: true }
  );

  selectedLanguage = signal<string>('');

  createPaymentId() {
    this.paymentId.set(new Date().getTime().toString())
  }

  onProductSelected(product: ISapiProduct | undefined) {
    if (product) {
      this.productId.set(product.id);
      this.languageList.set(
        product.language
          .split(',')
          .map((element: string) => element.toUpperCase())
      );
    }
  }

  onSubmit(): void {
    this.submitRequest.emit(
      {
        productId: this.productId(),
        subscriptionCreate: {
          paymentId: this.paymentId(),
          email: this.email(),
          language: this.selectedLanguage(),
          address: {
            firstName: this.firstName(),
            lastName: this.lastName(),
            street: this.street(),
            zip: this.zip(),
            city: this.city(),
            country: this.country(),
            company: this.company()
          }
        }
      }
    );
  }
}
