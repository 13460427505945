import { Component, computed, effect, inject, output, signal } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { ISapiProduct } from '../../../model/subscription-api/sapi-product';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';

@Component({
  selector: 'product-select',
  standalone: true,
  imports: [
    MatSelectModule,
    TranslateModule
  ],
  templateUrl: './product-select.component.html',
  styleUrl: './product-select.component.css'
})

export class ProductSelectComponent {

  productSelected = output<ISapiProduct | undefined>();

  sapi: SubscriptionApiService = inject(SubscriptionApiService);

  productList = signal<ISapiProduct[]>([]);
  selectedProductId = signal<string>('');

  selectedProduct = computed(
    () => {
      console.log(this.selectedProductId());
      // Find the selected product in the list and emit it as output event.
      let selectedProduct = this.productList()
        .find(
          product =>
            product.id === this.selectedProductId()
        )
      this.productSelected.emit(selectedProduct);
      return selectedProduct;
    }
  );


  constructor() {

    effect(() => {
      // Find the selected product in the list and emit it as output event.
      let selectedProduct = this.productList()
        .find(
          product =>
            product.id === this.selectedProductId()
        )
      this.productSelected.emit(selectedProduct);
    })

    this.sapi.readProducts().subscribe(
      products => {
        this.productList.set(products);
        this.selectedProductId.set(
          products.length > 0 ? products[0].id : ''
        );
      }
    )
  }
}
