import { Component, inject, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AdminNavigationComponent } from "./admin-navigation/admin-navigation.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    AdminNavigationComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  //title = 'subscription-admin';
  //message: string = 'Does not work :-(!';

  private router: Router = inject(Router);
  private translate: TranslateService = inject(TranslateService);

  constructor() {

    this.translate.setDefaultLang('de');
    //translate.use('en');
    /*this.translate.get('start.hello').subscribe((value: string) => {
      this.message = value;
    });*/
  }

  ngOnInit(): void {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          console.log('Navigation Start:', event);
        } else if (event instanceof NavigationEnd) {
          console.log('Navigation End:', event);
        } else if (event instanceof NavigationError) {
          console.error('Navigation Error:', event);
        } else if (event instanceof NavigationCancel) {
          console.warn('Navigation Cancelled:', event);
        }
      }
    );
  }
}