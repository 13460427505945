export enum PWADisplayMode {
    ANDROID_APP,
    IOS_APP,
    BROWSER
}

export class BrowserInfo {

    private static _isMobile: boolean;

    public static get hasTouchScreen(): boolean {

        let hasTouchScreen: boolean = false;

        if ('maxTouchPoints' in navigator) {

            hasTouchScreen = navigator.maxTouchPoints > 0;

        } else if ('msMaxTouchPoints' in navigator) {

            hasTouchScreen = navigator['msMaxTouchPoints'] > 0;
        } else {

            const mQ = matchMedia?.("(pointer:coarse)");

            if (mQ?.media === "(pointer:coarse)") {

                hasTouchScreen = !!mQ.matches;

            } else if ("orientation" in window) {

                hasTouchScreen = true; // deprecated, but good fallback

            }
        }

        return hasTouchScreen;
    }

    /*public static get isIOSApp(): boolean {
        return BrowserInfo.getCookie("app-platform") === "iOS App Store";
        //return true;
    }*/

    public static get isMobile(): boolean {

        // Lazy initialize _isMobile
        if (BrowserInfo._isMobile == null) {

            // If the information about touch screen is available (not null)
            // And the device does not have a touch screen, then it is
            // definetely not a mobile device (for us at least)
            if (BrowserInfo.hasTouchScreen == false) {
                BrowserInfo._isMobile = false;
            } else {

                // Has a tocuh screen (or no ifo available).
                // Stil might be not a mobile. So lets check userAgent
                const UA = navigator['userAgent'];
                // Only as a last resort, fall back to user agent sniffing
                BrowserInfo._isMobile =
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
            }
        }

        return BrowserInfo._isMobile;
    }

    /*    public static getCookie(cname: string): string {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }*/


    /*public static getPWADisplayMode(): PWADisplayMode {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
            return PWADisplayMode.ANDROID_APP;
        } else if (navigator['standalone'] || isStandalone) {
            return PWADisplayMode.IOS_APP;
        }
        return PWADisplayMode.BROWSER;
    }*/

    /*public static isCrhomeBasedBrowser(): boolean {
        //console.log('window.chrome',window['chrome']);

        return !!window['chrome'];
        // && (!!window['chrome']['webstore'] || !!window['chrome']['runtime']);
    }*/

    /*public static getBrowser(): string {
        const agent: string = navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf("edge") > -1: return "MS Edge";
            case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
            case agent.indexOf("opr") > -1 && !!window['opr']: return "Opera";
            case agent.indexOf("chrome") > -1 && !!window['chrome']: return "Chrome";
            case agent.indexOf("trident") > -1: return "MS IE";
            case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
            case agent.indexOf("safari") > -1: return "Safari";
            default: return "other";
        }
    }*/

    //https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
    /*public static getBrowser(): string {
        // Opera 8.0+
        var isOpera = (!!window['opr'] && !!window['opr'].addons) ||
            !!window['opera'] ||
            navigator.userAgent.indexOf(' OPR/') >= 0;

        // Firefox 1.0+
        var isFirefox = typeof window['InstallTrigger'] !== 'undefined';

        // Safari 3.0+ "[object HTMLElementConstructor]" 
        var isSafari = (
            function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            }
        )
            (!window['safari'] ||
                (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));

        // Internet Explorer 6-11
        var isIE = false || !!document['documentMode'];

        // Edge 20+
        var isEdge = !isIE && !!window['StyleMedia'];

        // Chrome 1 - 79
        var isChrome = !!window['chrome'] &&
            (!!window['chrome'].webstore || !!window['chrome'].runtime);

        // Edge (based on chromium) detection
        var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

        // Blink engine detection
        var isBlink = (isChrome || isOpera) && !!window.CSS;


        var output = 'Detecting browsers by ducktyping:<hr>';
        output += 'isFirefox: ' + isFirefox + '<br>';
        output += 'isChrome: ' + isChrome + '<br>';
        output += 'isSafari: ' + isSafari + '<br>';
        output += 'isOpera: ' + isOpera + '<br>';
        output += 'isIE: ' + isIE + '<br>';
        output += 'isEdge: ' + isEdge + '<br>';
        output += 'isEdgeChromium: ' + isEdgeChromium + '<br>';
        output += 'isBlink: ' + isBlink + '<br>';
        return output;
    }*/
}