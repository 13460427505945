import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { RoundButtonComponent } from '../round-button/round-button.component';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [
    RoundButtonComponent,
    TranslateModule
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  cancelBtnLabelId: string;
  confirmBtnLabelId: string;
  messageId: string;
  titleId: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public params: IConfirmDialogParams) {
    // Update view with given values
    this.cancelBtnLabelId = params.cancelBtnLabelId;
    this.confirmBtnLabelId = params.confirmBtnLabelId;
    this.titleId = params.titleId;
    this.messageId = params.messageId;
  }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

}

export interface IConfirmDialogParams {

  cancelBtnLabelId: string;
  confirmBtnLabelId: string;
  messageId: string;
  titleId: string;

}