import { Component, inject, signal } from '@angular/core';
import { ReqRespTab, ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { CreateSubscriptionSingleRequestComponent } from './request/create-subscription-single-request.component';
import { SubscriptionOutputFormComponent } from '../../common/subscription-output-form/subscription-output-form.component';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { ICreateSubscriptionSingleRequest } from '../../../model/request/i-create-subscription-single.request';
import { ErrorResponseComponent } from '../../common/error-response/error-response.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';

@Component({
  selector: 'create-subscription-single',
  standalone: true,
  imports: [
    CreateSubscriptionSingleRequestComponent,
    SubscriptionOutputFormComponent,
    ErrorResponseComponent,
    ReqRespTabGroupComponent
  ],
  templateUrl: './create-subscription-single.component.html',
  styleUrl: './create-subscription-single.component.css'
})
export class CreateSubscriptionSingleComponent {

  sapi: SubscriptionApiService = inject(SubscriptionApiService);

  activeTabIndex = signal<ReqRespTab>(ReqRespTab.REQUEST);
  errorResponse = signal<HttpErrorResponse | null>(null);

  errorVisible = signal<boolean>(false);
  responseDisabled = signal<boolean>(false);
  responseVisible = signal<boolean>(false);

  subscription = signal<SapiSubscription | null>(null);

  onSubmit(params: ICreateSubscriptionSingleRequest): void {
    //console.log(params);
    this.sapi.create(
      params.productId,
      params.subscriptionCreate
    ).subscribe(
      {
        next: subscription => {

          this.activeTabIndex.set(ReqRespTab.RESPONSE);

          this.errorVisible.set(false);

          this.responseDisabled.set(false);
          this.responseVisible.set(true);

          this.subscription.set(new SapiSubscription(subscription));

          console.log(subscription);
        },
        error: error => {

          this.activeTabIndex.set(ReqRespTab.ERROR);

          this.errorVisible.set(true);

          this.responseVisible.set(false);

          this.errorResponse.set(error);
          console.log(error);
        }
      }
    );
  }
}
