<form #readByPaymentIdForm="ngForm">
    <product-select (productSelected)="onProductSelected($event)"></product-select>

    <mat-form-field>
        <mat-label>{{'crete_single.payment_id_label' | translate}}</mat-label>

        <input name="payment-id" matInput [(ngModel)]="paymentId" #payment_id="ngModel" required>

        @if (paymentId().length > 0) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="paymentId.set('')">
            <mat-icon>close</mat-icon>
        </button>
        }

        @if((payment_id.dirty || payment_id.touched) &&
        (payment_id.invalid && payment_id.errors?.['required'])
        ) {
        <mat-error>{{'crete_single.payment_id_required' | translate}}</mat-error>
        }

    </mat-form-field>    

    <button mat-raised-button class="find-button" [disabled]="!readByPaymentIdForm.form.valid" (click)="onSubmit()">
        {{'read.find' | translate}}
    </button>    
</form>