<req-resp-tab-group
    [(activeTabIndex)]="activeTabIndex"
    [errorVisible]="errorVisible()"
    [request2Disabled]="request2Disabled()"
    [request2Visible]="request2Visible()"
    [responseDisabled]="responseDisabled()" 
    [responseVisible]="responseVisible()" 
>
    <email-request class="req-tab" (submitRequest)="onSubmitEmail($event)">
    </email-request>

    <!--<subscription-input-form class="req-2-tab" [subscription]="subscription()" (update)="onUpdate($event)">
    </subscription-input-form>-->

    <subscription-list class="req-2-tab" [subscriptions]="subscriptions()" [inputForm]="true" (update)="onUpdate($event)">
    </subscription-list>


    <subscription-output-form class="resp-tab" [subscription]="newSubscription()">
    </subscription-output-form>
    
    <error-response class="error-tab" [error]="errorResponse()">
    </error-response>
</req-resp-tab-group>