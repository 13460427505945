
export class SessionStorageKey {

  private static map: Map<SessionStorageFeature, string>;

  static get(feature: SessionStorageFeature): string {
    if (SessionStorageKey.map == null) {
      SessionStorageKey._initialize();
    }
    return SessionStorageKey.map.get(feature) || '';
  }

  private static _initialize() {
    SessionStorageKey.map = new Map<SessionStorageFeature, string>();
    SessionStorageKey.map.set(SessionStorageFeature.PRODUCT_LIST, SessionStorageFeature.PRODUCT_LIST);
  }
}

export enum SessionStorageFeature {

  /* Named keys */

  PRODUCT_LIST = 'product_list'
}