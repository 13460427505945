import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

/**
 * The component is used to display a button with a rounded coreners.
 * e.g. Start Test button on the Test Topic Filter Page component
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'round-button',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  templateUrl: './round-button.component.html',
  styleUrls: ['./round-button.component.css']
})
export class RoundButtonComponent implements OnInit {

  backgroundColor = input<string>('#03914a');
  labelId = input.required<string>();

  constructor() { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }

}
