<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="'create'" (click)="titleId.set('navigation.create_subscription')">{{'navigation.create' | translate}}</a>
      <a mat-list-item [routerLink]="'read'" (click)="titleId.set('navigation.read_subscription')">{{'navigation.read' | translate}}</a>
      <a mat-list-item [routerLink]="'update'"  (click)="titleId.set('navigation.update_subscription')">{{'navigation.update' | translate}}</a>
      <a mat-list-item [routerLink]="'delete'" (click)="titleId.set('navigation.delete_subscription')">{{'navigation.delete' | translate}}</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <!--@if (isHandset$ | async) {-->
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      <!--}-->
      <span>{{titleId() | translate}}</span>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
