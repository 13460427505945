import { Routes } from '@angular/router';
import { CreateSubscriptionComponent } from './component/create-subscription/create-subscription.component';
import { ReadSubscriptionComponent } from './component/read-subscription/read-subscription.component';
import { UpdateSubscriptionComponent } from './component/update-subscription/update-subscription.component';
import { DeleteSubscriptionComponent } from './component/delete-subscription/delete-subscription.component';
import { CreateSubscriptionSingleComponent } from './component/create-subscription/single/create-subscription-single.component';
import { CreateSubscriptionMultipleComponent } from './component/create-subscription/multiple/create-subscription-multiple.component';
import { ReadSubscriptionByEmailComponent } from './component/read-subscription/by-email/read-subscription-by-email.component';
import { ReadSubscriptionByPaymentIdComponent } from './component/read-subscription/by-payment-id/read-subscription-by-payment-id.component';
import { ReadSubscriptionByUserIdComponent } from './component/read-subscription/by-user-id/read-subscription-by-user-id.component';
import { AuthGuard } from './service/auth/auth.guard';
import { LoginComponent } from './component/auth/login/login.component';
import { UpdateSubscriptionByUserIdComponent } from './component/update-subscription/by-user-id/update-subscription-by-user-id.component';
import { UpdateSubscriptionByPaymentIdComponent } from './component/update-subscription/by-payment-id/update-subscription-by-payment-id.component';
import { UpdateSubscriptionBySubscriptionIdComponent } from './component/update-subscription/by-subscription-id/update-subscription-by-subscription-id.component';
import { UpdateSubscriptionByEmailComponent } from './component/update-subscription/by-email/update-subscription-by-email.component';
import { DeleteSubscriptionBySubscriptionIdComponent } from './component/delete-subscription/delete-subscription-by-subscription-id/delete-subscription-by-subscription-id.component';

export const routes: Routes = [
    {
        path: 'create',
        component: CreateSubscriptionComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'single',
                component: CreateSubscriptionSingleComponent
            },
            {
                path: 'multiple',
                component: CreateSubscriptionMultipleComponent
            }
        ]
    },
    {
        path: 'read',
        component: ReadSubscriptionComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'email',
                component: ReadSubscriptionByEmailComponent
            },
            {
                path: 'payment-id',
                component: ReadSubscriptionByPaymentIdComponent
            },
            {
                path: 'user-id',
                component: ReadSubscriptionByUserIdComponent
            }
        ]
    },
    {
        path: 'update',
        component: UpdateSubscriptionComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'email',
                component: UpdateSubscriptionByEmailComponent
            },
            {
                path: 'payment-id',
                component: UpdateSubscriptionByPaymentIdComponent
            },
            {
                path: 'subscription-id',
                component: UpdateSubscriptionBySubscriptionIdComponent
            },
            {
                path: 'user-id',
                component: UpdateSubscriptionByUserIdComponent
            }

        ]
    },
    {
        path: 'delete',
        component: DeleteSubscriptionComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'subscription-id',
                component: DeleteSubscriptionBySubscriptionIdComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    }/*,
    { 
        path: '**', 
        redirectTo: 'read/user-id'
    }*/
];
