import { inject, Injectable } from "@angular/core";
import { SapiParam } from "../../model/subscription-api/enum/sapi-param.enum";
import { PropertiesService } from "../properties/properties.service";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionApiUrlService {

    private readonly properties: PropertiesService = inject(PropertiesService);

    private readonly baseUrl: string;
    private readonly createQuery: string;
    private readonly readQuery: string;
    private readonly readByEmailQuery: string;
    private readonly readByPaymentIdQuery: string;
    private readonly readByUserIdQuery: string;
    private readonly readProductsQuery: string;
    private readonly updateQuery: string;

    constructor() {
        this.baseUrl = this.properties.subscriptionApi.baseUrl;

        this.createQuery = this.properties
            .subscriptionApi
            .query
            .create;

        this.readQuery = this.properties
            .subscriptionApi
            .query
            .read;

        this.readByEmailQuery = this.properties
            .subscriptionApi
            .query
            .readByEmail;

        this.readByPaymentIdQuery = this.properties
            .subscriptionApi
            .query
            .readByPaymentId;


        this.readByUserIdQuery = this.properties
            .subscriptionApi
            .query
            .readByUserId;

        this.readProductsQuery = this.properties
            .subscriptionApi
            .query
            .readProducts;

        this.updateQuery = this.properties
            .subscriptionApi
            .query
            .update;
    }

    public getCreateUrl(
        productId: string
    ): string {

        return this.baseUrl.concat(
            this.createQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
        );
    }


    public getReadUrl(
        productId: string,
        subscriptionId: string
    ): string {

        return this.baseUrl.concat(
            this.readQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
                .replace(
                    SapiParam.SUSBCRIPTION_ID,
                    subscriptionId
                )
        );
    }

    public getReadByEmailUrl(
        productId: string,
        email: string
    ): string {

        return this.baseUrl.concat(
            this.readByEmailQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
                .replace(
                    SapiParam.EMAIL,
                    email
                )
        );
    }
    
    public getReadByPaymentIdUrl(
        productId: string,
        paymentId: string
    ): string {

        return this.baseUrl.concat(
            this.readByPaymentIdQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
                .replace(
                    SapiParam.PAYMENT_ID,
                    paymentId
                )
        );
    }


    public getReadByUserIdUrl(
        productId: string,
        userId: string
    ): string {

        return this.baseUrl.concat(
            this.readByUserIdQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
                .replace(
                    SapiParam.USER_ID,
                    userId
                )
        );
    }

    public getReadProductsUrl(): string {

        return this.baseUrl
            .concat(this.readProductsQuery);
    }

    public getUpdateUrl(
        productId: string,
        subscriptionId: string
    ): string {

        return this.baseUrl.concat(
            this.updateQuery
                .replace(
                    SapiParam.PRODUCT_ID,
                    productId
                )
                .replace(
                    SapiParam.SUSBCRIPTION_ID,
                    subscriptionId
                )
        );
    }
}

