import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable(
    { providedIn: 'root' }
)
export class AuthGuard implements CanActivate {

    auth: AuthService = inject(AuthService);
    router: Router = inject(Router);

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> |
        Promise<boolean | UrlTree> |
        boolean |
        UrlTree {
            
        return this.auth.isLoggedIn ?
            true :
            this.router.createUrlTree(
                ['login'], 
                { 
                    queryParams: { returnUrl: state.url } 
                }
            );
    }
}