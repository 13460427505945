@if(error() && error()?.status===0) {
    
    <p><strong>Status:</strong> {{error()?.statusText}}</p>
    <p><strong>Error message:</strong> {{error()?.message}}</p>

}

@if(error() && 
    (error()?.status === 400 
    || error()?.status === 401
    || error()?.status === 404
    || error()?.status === 500)
) {
    
    <p><strong>Status:</strong>{{error()?.status}} : {{error()?.statusText}}</p>
    <p><strong>Error message:</strong> {{error()?.message}}</p>
    <p><strong>SAPI message:</strong> {{error()?.error.message}}</p>

}
