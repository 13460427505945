import { Component, computed, Signal, signal } from '@angular/core';
import { ProductSelectComponent } from '../../../common/product-select/product-select.component';
import { ISapiProduct } from '../../../../model/subscription-api/sapi-product';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MyErrorStateMatcher } from '../../single/request/create-subscription-single-request.component';

@Component({
  selector: 'create-subscription-multiple-request',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    //    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    //MatSelectModule,
    ProductSelectComponent,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './create-subscription-multiple-request.component.html',
  styleUrl: './create-subscription-multiple-request.component.css'
})
export class CreateSubscriptionMultipleRequestComponent {

  product = signal<ISapiProduct | undefined>(undefined);
  firstNumber = signal<number>(1);
  firstNumberFormControl = new FormControl('', [Validators.required, Validators.min(1)]);
  lastNumber = signal<number>(1);

  lastNumberFormControl: Signal<FormControl> = computed<FormControl>(() => {
    return new FormControl(
      this.lastNumber()>this.firstNumber() ? this.lastNumber() : this.firstNumber(), 
      [Validators.required, Validators.min(this.firstNumber())]
    );
  })

  userIdNumberLength = signal<number>(4);
  userIdNumberLengthFormControl = new FormControl('', [Validators.required, Validators.min(1)]);
  userIdTemplate = signal<string>('ed%s');
  userIdTemplateFormControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();


  onProductSelected(product: ISapiProduct | undefined) {
    this.product.set(product);
  }
}
