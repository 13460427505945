<form #findByEmailForm="ngForm">

    <product-select (productSelected)="onProductSelected($event)"></product-select>

    <email-input [(email)]="email" [(isValid)]="emailValid"></email-input>

    <button mat-raised-button class="create-button" [disabled]="!findByEmailForm.form.valid || !emailValid()"
        (click)="onSubmit()">
        {{'read.find' | translate}}
    </button>

</form>