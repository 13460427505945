import { Component, computed, effect, input, output, signal } from '@angular/core';
import { SapiSubscription } from '../../../../model/subscription-api/sapi-subscription';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SubscriptionOutputFormComponent } from '../../subscription-output-form/subscription-output-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionInputFormComponent } from '../../subscription-input-form/subscription-input-form.component';
import { ISubscriptionUpdateEvent } from '../../../../model/event/i-subscription-update.event';
import { ISapiSubscriptionUpdate } from '../../../../model/subscription-api/sapi-subscription-update';

@Component({
  selector: 'subscription-list',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    SubscriptionInputFormComponent,
    SubscriptionOutputFormComponent,
    TranslateModule
  ],
  templateUrl: './subscription-list.component.html',
  styleUrl: './subscription-list.component.css',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class SubscriptionListComponent {

  inputForm = input(false)
  update = output<ISubscriptionUpdateEvent>();

  subscriptions = input<SapiSubscription[]>([]);

  expandFirstRow = effect(
    () => {
      console.log(this.subscriptions()[0]);
      this.subscriptions()[0] && this.expandedElement.set(this.subscriptions()[0]);
    },
    { allowSignalWrites: true }
  );

  expandedElement = signal<SapiSubscription | null>(null);

  columnsToDisplay = signal(['creationDate', 'userId', 'paymentId', 'lastAccessTime']);
  columnsToDisplayWithExpand = computed(() => [...this.columnsToDisplay(), 'expand']);

  onUpdate(productId: string, subscriptionId: string, subscriptionUpdate: ISapiSubscriptionUpdate) {
    this.update.emit(
      {
        productId : productId,
        subscriptionId : subscriptionId,
        subscriptionUpdate : subscriptionUpdate
      }
    );
  }
}
