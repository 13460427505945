@if(subscription()) {

<div class="subscription-container">

    <form #updateForm="ngForm">

        <mat-accordion class="accordion-headers-align">

            <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{'subscription_input.panel_title' | translate}}</mat-panel-title>
                    <mat-panel-description>
                        {{'subscription_input.panel_description' | translate}}
                        <mat-icon>info</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-form-field>
                    <mat-label>{{'subscription_input.product_label' | translate}}</mat-label>

                    <input name="product" matInput [value]="product()" readonly>

                    <mat-hint align="start">{{'subscription_input.product_hint' | translate}}</mat-hint>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'subscription_input.payment_id_label' | translate}}</mat-label>

                    <input name="payment-id" matInput [value]="paymentId()" readonly>

                    <mat-hint align="start">{{'subscription_input.payment_id_hint' | translate}}</mat-hint>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'subscription_input.user_id_label' | translate}}</mat-label>

                    <input name="user-id" matInput [(ngModel)]="userId" #user_id="ngModel" required>

                    @if (userId().length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="userId.set('')">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                    @if((user_id.dirty || user_id.touched) && user_id.invalid && user_id.errors?.['required']) {

                    <mat-error>{{'subscription_input.user_id_required' | translate}}</mat-error>
                    }

                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'subscription_input.password_label' | translate}}</mat-label>

                    <input name="password" matInput [(ngModel)]="password" #password_input="ngModel" required>

                    @if (password().length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="password.set('')">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                    @if((password_input.dirty || password_input.touched) && password_input.invalid &&
                    password_input.errors?.['required']) {

                    <mat-error>{{'subscription_input.password_required' | translate}}</mat-error>
                    }

                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'subscription_input.email_label' | translate}}</mat-label>

                    <input name="email" matInput [(ngModel)]="email" #email_input="ngModel" required>

                    @if (email().length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="email.set('')">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                    @if((email_input.dirty || email_input.touched) && email_input.invalid &&
                    email_input.errors?.['required']) {

                    <mat-error>{{'subscription_input.email_required' | translate}}</mat-error>
                    }

                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'subscription_input.language_label' | translate}}</mat-label>

                    <input name="language" matInput [value]="language()" readonly>

                    <mat-hint align="start">{{'subscription_input.language_hint' | translate}}</mat-hint>
                </mat-form-field>


                <mat-form-field>
                    <mat-label>{{'subscription_input.creation_date_label' | translate}}</mat-label>

                    <input name="creation_date" matInput [value]="creationDate()" readonly>

                    <mat-hint align="start">{{'subscription_input.creation_date_hint' | translate}}</mat-hint>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'subscription_input.start_date_label' | translate}}</mat-label>
                    <input name="start_date" matInput [matDatepicker]="picker" [(ngModel)]="startDate">
                    <mat-hint>{{'subscription_input.start_date_hint' | translate}}</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'subscription_input.length_label' | translate}}</mat-label>

                    <input name="length" type="number" matInput [(ngModel)]="length" #length_input="ngModel" required>

                    @if((length_input.dirty || length_input.touched) && length_input.invalid &&
                    length_input.errors?.['required']) {

                    <mat-error>{{'subscription_input.length_required' | translate}}</mat-error>
                    }

                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'subscription_input.last_access_time_label' | translate}}</mat-label>

                    <input name="last_access_time" matInput [value]="lastAccessTime()" readonly>

                </mat-form-field>

                <mat-action-row>
                    <button mat-button (click)="nextStep()">Next</button>
                </mat-action-row>

            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step() === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{'address_panel.title' | translate}}</mat-panel-title>
                    <mat-panel-description>
                        {{'address_panel.description' | translate}}
                        <mat-icon>home</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <address-input-form [(address)]="address"></address-input-form>

                <mat-action-row>
                    <button mat-button (click)="nextStep()">Next</button>
                </mat-action-row>

            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step() === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{'device_panel.device_1' | translate}}</mat-panel-title>
                    <mat-panel-description>
                        {{'device_panel.mobile' | translate}}
                        <mat-icon>smartphone</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <device-input-form [device]="mobileDevice()"></device-input-form>

                <mat-action-row>
                    <button mat-button (click)="prevStep()">Previous</button>
                    <button mat-button (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step() === 3" (opened)="setStep(3)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{'device_panel.device_2' | translate}}</mat-panel-title>
                    <mat-panel-description>
                        {{'device_panel.desktop' | translate}}
                        <mat-icon>computer</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <device-input-form [device]="desktopDevice()"></device-input-form>

                <mat-action-row>
                    <button mat-button (click)="prevStep()">Previous</button>
                    <button mat-button (click)="nextStep()">End</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
 
        <button mat-raised-button class="update-button" [disabled]="!updateForm.form.valid" (click)="onSubmit()">
            {{'subscription_input.update_button_label' | translate}}
        </button>

    </form>
</div>
}