export interface ISapiAddress {
    readonly city: string;
    readonly company: string;
    readonly country: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly street: string;
    readonly zip: string;
}

export class SapiAddress {

    public static dummyAddress(): SapiAddress {
        return new SapiAddress(
            {
                city: '',
                company: '',
                country: '',
                firstName: '',
                lastName: '',
                street: '',
                zip: ''
            }
        );
    }

    city: string = '';
    company: string = '';
    country: string = '';
    firstName: string = '';
    lastName: string = '';
    street: string = '';
    zip: string = '';

    /*constructor(city: string,
        company: string,
        country: string,
        firstName: string,
        lastName: string,
        street: string,
        zip: string
    ) {
        this.city = city;
        this.company = company;
        this.country = country;
        this.firstName = firstName;
        this.lastName = lastName;
        this.street = street;
        this.zip = zip;
    }*/
    constructor(source: ISapiAddress) {
        this.city = source.city;
        this.company = source.company;
        this.country = source.country;
        this.firstName = source.firstName;
        this.lastName = source.lastName;
        this.street = source.street;
        this.zip = source.zip;
    }

}
