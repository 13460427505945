import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'output-form-row',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './output-form-row.component.html',
  styleUrl: './output-form-row.component.css'
})
export class OutputFormRowComponent {

  translate = inject(TranslateService);

  static labelWidth = signal(1);

  labelId = input('');
  value = input('');

  labelWidthCalc = effect(
    () => {
      const label = this.translate.instant(this.labelId());
      OutputFormRowComponent.labelWidth.update(value => label.length > value ? label.length : value);
    },
    { allowSignalWrites: true }
  );

  labelWidth = computed<string>(() => OutputFormRowComponent.labelWidth() + 'ch');

}
