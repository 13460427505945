<req-resp-tab-group
    [(activeTabIndex)]="activeTabIndex"
    [errorVisible]="errorVisible()"
    [request2Disabled]="true"
    [request2Visible]="false"
    [responseDisabled]="responseDisabled()" 
    [responseVisible]="responseVisible()" 
>
    <create-subscription-single-request class="req-tab" (submitRequest)="onSubmit($event)">
    </create-subscription-single-request>
    <subscription-output-form class="resp-tab" [subscription]="subscription()">
    </subscription-output-form>
    <error-response class="error-tab" [error]="errorResponse()">
    </error-response>
</req-resp-tab-group>