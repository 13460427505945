import { SapiDeviceType, SapiDeviceTypeFactory } from "./enum/sapi-device-type.enum";

export interface ISapiDevice {

    readonly type: string;
    readonly deviceId: string;
    readonly model: string;
    readonly operationSystem: string;
}
export class SapiDevice {

    public static dummyDevice(): SapiDevice {
        return new SapiDevice(
            {
                type: 'MOBILE',
                deviceId: '',
                model: '',
                operationSystem: ''
            }
        );
    }

    type: SapiDeviceType;
    deviceId: string = '';
    model: string = '';
    operationSystem: string = '';

    constructor(source: ISapiDevice) {
        this.type = SapiDeviceTypeFactory
            .fromString(source.type);
        this.deviceId = source.deviceId;
        this.model = source.model;
        this.operationSystem = source.operationSystem;
    }

}
