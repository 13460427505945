<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="activeTabIndex">

    <mat-tab label="{{'req_resp_tab_group.request' | translate}}">
        <ng-content select=".req-tab"></ng-content>
    </mat-tab>

    @if(request2Visible()) {
    <mat-tab label="{{'req_resp_tab_group.request2' | translate}}" [disabled]="request2Disabled()">
        <ng-content select=".req-2-tab"></ng-content>
    </mat-tab>
    }

    @if(responseVisible()) {
    <mat-tab label="{{'req_resp_tab_group.response' | translate}}" [disabled]="responseDisabled()">
        <ng-content select=".resp-tab"></ng-content>
    </mat-tab>
    }

    @if (errorVisible()) {
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="error-tab-icon">error</mat-icon>
            <span class="error-tab-label">{{'req_resp_tab_group.error' | translate}}</span>
        </ng-template>
        <ng-content select=".error-tab"></ng-content>
    </mat-tab>
    }

</mat-tab-group>