import { Component, output, signal } from '@angular/core';
import { ProductSelectComponent } from '../../product-select/product-select.component';
import { ISapiProduct } from '../../../../model/subscription-api/sapi-product';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { EmailInputComponent } from '../../email-input/email-input.component';
import { IEmailRequest } from '../../../../model/request/i-email.request';

@Component({
  selector: 'email-request',
  standalone: true,
  imports: [
    EmailInputComponent,
    FormsModule,
    MatButtonModule,
    ProductSelectComponent,
    TranslateModule
  ],
  templateUrl: './email-request.component.html',
  styleUrl: './email-request.component.css'
})
export class EmailRequestComponent {

  submitRequest = output<IEmailRequest>();

  email = signal<string>('ruslan.rubtsov@gmail.com');
  emailValid = signal<boolean>(true);
  productId = signal<string>('');

  onProductSelected(product: ISapiProduct | undefined) {
    if (product) {
      this.productId.set(product.id);
    }
  }

  onSubmit(): void {
    this.submitRequest.emit(
      {
        productId: this.productId(),
        email: this.email().trim()
      }
    );
  }


}
