import { BrowserInfo } from "../../../utils/browser-info";

export enum SapiDeviceType {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE'
}

export class SapiDeviceTypeFactory {

    public static get thisDeviceType(): SapiDeviceType {
        return BrowserInfo.isMobile ? SapiDeviceType.MOBILE : SapiDeviceType.DESKTOP
    }

    public static fromString(stringValue: string): SapiDeviceType {

        let result: SapiDeviceType = SapiDeviceType.DESKTOP;
        Object.values(SapiDeviceType)
            .forEach(
                (value: SapiDeviceType) => {
                    if (stringValue == value) {
                        result = value;
                    }
                }
            )
        return result;
    }
}