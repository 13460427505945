<req-resp-tab-group
    [(activeTabIndex)]="activeTabIndex"
    [errorVisible]="errorVisible()"
    [responseDisabled]="responseDisabled()" 
    [responseVisible]="responseVisible()" 
>
    <user-id-request class="req-tab" (submitRequest)="onSubmit($event)">
    </user-id-request>

    <subscription-output-form class="resp-tab" [subscription]="subscription()">
    </subscription-output-form>
    
    <error-response class="error-tab" [error]="errorResponse()">
    </error-response>
</req-resp-tab-group>