import { inject, Injectable } from "@angular/core";
import { PersistentStateService } from "../persistent-state/persistent-state.service";
import { PropertiesService } from "../properties/properties.service";
import { Feature } from "../persistent-state/persistent-state-key";
import { IProperties_Authentication } from "../../model/properties/properties.interface";

@Injectable({
    providedIn: "root",
})
export class AuthService {

    //private readonly persistentState: PersistentStateService = inject(PersistentStateService);

    private authentication: IProperties_Authentication = {} as IProperties_Authentication;
    private sessionLength: number = 0;


    constructor(
        private persistentState: PersistentStateService,
        properties: PropertiesService
    ) {
        properties.loadComplete$.subscribe(
            () => {
                this.authentication = properties.authentication;
                this.sessionLength = properties.authentication.sessionLength * 60 * 1000;
            }
        )
    }

    public get isLoggedIn(): boolean {

        if (!this.loginTimeExists) {
            return false;
        }

        return this.now - this.loginTime <
            this.sessionLength;
    }

    public login(userId: string, password: string): boolean {
        this.authentication.users.forEach(
            user => {
                if (user.userId === userId && user.password === password) {
                    this.persistentState.set(Feature.LOGIN_TIME, this.now);
                }
            }
        );

        return this.isLoggedIn;
    }

    private get now(): number {
        return new Date().getTime();
    }

    private get loginTimeExists(): boolean {
        return this.persistentState.has(Feature.LOGIN_TIME);
    }

    private get loginTime(): number {
        return this.persistentState.get(Feature.LOGIN_TIME);
    }


}

/*export async function authenticationProviderFactory(
    persistentState: PersistentStateService,
    properties: PropertiesService
): Promise<AuthService> {
    await properties.load();
    return new AuthService(persistentState, properties);
}
*/