<req-resp-tab-group
    [(activeTabIndex)]="activeTabIndex"
    [errorVisible]="errorVisible()"
    [request2Disabled]="request2Disabled()"
    [request2Visible]="request2Visible()"
    [responseDisabled]="responseDisabled()" 
    [responseVisible]="responseVisible()" 
>
    <user-id-request class="req-tab" (submitRequest)="onSubmitUserId($event)">
    </user-id-request>

    <subscription-input-form class="req-2-tab" [subscription]="subscription()" (update)="onUpdate($event)">
    </subscription-input-form>

    <subscription-output-form class="resp-tab" [subscription]="newSubscription()">
    </subscription-output-form>
    
    <error-response class="error-tab" [error]="errorResponse()">
    </error-response>
</req-resp-tab-group>