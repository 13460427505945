import { Component, inject } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Link } from '../../model/link';

@Component({
  selector: 'update-subscription',
  standalone: true,
  imports: [
    MatTabsModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './update-subscription.component.html',
  styleUrl: './update-subscription.component.css'
})
export class UpdateSubscriptionComponent {
  route: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);

  links: Link[] = [
    { id: 0, text: 'update.by_user_id', path: 'user-id' },
    { id: 1, text: 'update.by_email', path: 'email' },
    { id: 2, text: 'update.by_payment_id', path: 'payment-id' },
    { id: 3, text: 'update.by_subscription_id', path: 'subscription-id' }
  ];
  activeLink: Link = this.links[0];

  ngOnInit(): void {
    this.router.navigate([this.activeLink.path], { relativeTo: this.route });
  }
}
